var headroom, myElement;

myElement = document.querySelector("header");

headroom = new Headroom(myElement, {
  offset: 200
});

headroom.init();

document.addEventListener('DOMContentLoaded', function() {
  var $cForm;
  $('a[scroll-page]').on('click', function(e) {
    var offset, scrollPage;
    e.preventDefault();
    offset = 0;
    scrollPage = new SmoothScroll();
    return scrollPage.animateScroll(document.querySelector($(this).attr('href')), 0, {
      updateURL: false,
      offset: offset,
      speed: 200,
      speedAsDuration: true
    });
  });
  if ($('#cForm').length > 0) {
    $cForm = $('#cForm');
    return $cForm.ajaxForm({
      beforeSubmit: function(arr, $form, options) {
        // disable all controll elements
        $cForm.find('.form-control, .btn, .form-check-input, .form-select').prop('disabled', function() {
          return !$(this).prop('disabled');
        });
        return $cForm.find('.cload').removeClass('invisible');
      },
      success: function(responseText, statusText, xhr, $form) {
        
        // enable all controll elements
        $cForm.find('.form-control, .btn, .form-check-input, .form-select').prop('disabled', function() {
          return !$(this).prop('disabled');
        });
        $cForm.find('.cload').addClass('invisible');
        return jQuery.notify(responseText, {
          arrowShow: false,
          autoHide: true,
          position: 'bottom right',
          className: 'success',
          autoHideDelay: 3000
        });
      },
      error: function(responseText, statusText, xhr, $form) {
        // enable all controll elements
        $cForm.find('.form-control, .btn, .form-check-input, .form-select').prop('disabled', function() {
          return !$(this).prop('disabled');
        });
        $cForm.find('.cload').addClass('invisible');
        return jQuery.notify(responseText.responseText, {
          arrowShow: false,
          autoHide: true,
          position: 'bottom right',
          className: 'error',
          autoHideDelay: 3000
        });
      },
      url: 'core/start.php',
      type: 'post',
      clearForm: true,
      resetForm: true
    });
  }
});
